//
// This component displays prev/next projects based on the slug its given
// (excluding any projects that are set to "Coming Soon")
//
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Image from "./image"
import styles from "./projectNav.module.scss"

const nextItem = (i, arr) => {
  i = i + 1 // increase i by one
  i = i % arr.length // if we've gone too high, start from `0` again
  return arr[i].project[0] // give us back the item of where we are now
}

const prevItem = (i, arr) => {
  if (i === 0) {
    // i would become 0
    i = arr.length // so put it at the other end of the array
  }
  i = i - 1 // decrease by one
  return arr[i].project[0] // give us back the item of where we are now
}

const ProjectNav = props => {
  const data = useStaticQuery(
    graphql`
      query ProjectsNav {
        craft {
          featuredProjects: entries(section: "home") {
            ... on Craft_home_home_Entry {
              projectCarousel {
                ... on Craft_projectCarousel_BlockType {
                  project {
                    ... on Craft_projects_projects_Entry {
                      id
                      title
                      comingSoon
                      slug
                      uri
                      coverImages {
                        ... on Craft_coverImages_BlockType {
                          ratio_16x9 {
                            id
                            url
                            width
                            height
                            ... on Craft_zjContent_Asset {
                              altText
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const projects = data.craft.featuredProjects[0].projectCarousel
  const liveProjects = projects.filter(
    item =>
      item.project[0] != null && item.project[0].comingSoon === "published"
  )

  const index = liveProjects
    .map(item => {
      return item.project[0].slug
    })
    .indexOf(props.slug)
  const projectIndex = (index === -1) ? 0 : index;

  const nextProject = nextItem(projectIndex, liveProjects)
  const prevProject = prevItem(projectIndex, liveProjects)
  const prevThumbnail = prevProject.coverImages[0].ratio_16x9[0]
  const nextThumbnail = nextProject.coverImages[0].ratio_16x9[0]

  return (
    <div className={styles.nav}>
      <div className="wrapper">
        <div className={`columns is-tablet ${styles.columns}`}>
          <div className="column is-6-tablet">
            <Link to={`/${nextProject.uri}`}>
              <div className={styles.navNext}>
                <div className={`body-large-after-desktop ${styles.text}`}>
                  <span>Next</span>
                  <br />
                  <span className={styles.textTitle}>{nextProject.title}</span>
                </div>
                <div className={styles.imageWrapper}>
                  <Image
                    width={nextThumbnail.width}
                    height={nextThumbnail.height}
                    // Excluding the gutters to account for scaling on hover with CSS
                    sizes="(min-width: 1344px) 690px, (min-width: 768px) calc(50vw), calc(100vw)"
                    url={nextThumbnail.url}
                    alt={nextThumbnail.altText}
                  />
                </div>
              </div>
            </Link>
          </div>
          <div className="column is-6-tablet">
            <Link to={`/${prevProject.uri}`}>
              <div className={styles.navPrev}>
                <div className={`body-large-after-desktop ${styles.text}`}>
                  <span>Previous</span>
                  <br />
                  <span className={styles.textTitle}>{prevProject.title}</span>
                </div>
                <div className={styles.imageWrapper}>
                  <Image
                    width={prevThumbnail.width}
                    height={prevThumbnail.height}
                    // Excluding the gutters to account for scaling on hover with CSS
                    sizes="(min-width: 1344px) 690px, (min-width: 768px) calc(50vw), calc(100vw - 2.5rem)"
                    url={prevThumbnail.url}
                    alt={prevThumbnail.altText}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectNav
